import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { MenuProduct } from "./MenuProduct";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";

export const MenuCategory = ({category}) => {

  const products = useSelector((state) => state.product.products.filter((theProduct) => theProduct.category?._id === category?._id))

  return (
    <Box maxWidth="600px" margin="0 auto">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={category._id}
        >
          <Typography>{category.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
          {products && products.length > 0 && (
            products.map(product => (<MenuProduct key={product._id}  product={product} />))
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}