//const API_URL = "http://localhost:3000"
const API_URL = "https://epyx86anyl.execute-api.us-east-1.amazonaws.com/dev"

const LOGIN_URL = '/login';
export const zumosApiConstants = {
    CATEGORY_RESOURCE:  API_URL + "/api/category",
    PRODUCT_RESOURCE:  API_URL + "/api/product",
}

export const productConstants = {
    UNKNOWN_PRODUCT_ID: '66fb66666a6fdfdd6ea6a66a'
}
