import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import ReactGA from "react-ga4";

export default function FooterComponent() {

  const handleFooterClick = (url: string, name: string) => {
    ReactGA.event({
      category: 'User',
      action: 'Pie de página - ' + name,
    });
    window.open(url, "_blank");
  }

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
        marginTop: '1px',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {/*<Grid item xs={12} sm={4}>*/}
          {/*  <Typography variant="h6" color="text.primary" gutterBottom>*/}
          {/*    About Us*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body2" color="text.secondary">*/}
          {/*    We are XYZ company, dedicated to providing the best service to our*/}
          {/*    customers.*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Pedidos
            </Typography>

            <Button
              component={Link}
              variant="text"
              color="warning"
              sx={{
                color: '#002984'
              }}
              size="large"
              startIcon={<CallIcon />}
              style={{marginRight: "5px"}}
              onClick={() => handleFooterClick("tel:59843868", "Teléfono") }
            >
              5984 3868
            </Button>
            <br/>
            <Button
              component={Link}
              variant="text"
              color="warning"
              sx={{
                color: '#128C7E'
              }}
              size="large"
              startIcon={<WhatsAppIcon />}
              style={{marginRight: "5px"}}
              onClick={() => handleFooterClick("https://api.whatsapp.com/send?phone=50259843868", "WhatsApp") }
            >
              WhatsApp
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Síguenos
            </Typography>

            <Button
              variant="text"
              color="secondary"
              sx={{
                color: '#1B74E4'
              }}
              size="large"
              startIcon={<FacebookIcon />}
              style={{marginRight: "5px"}}
              onClick={() => handleFooterClick("https://www.facebook.com/zumosgt", "Facebook") }
            >
              Facebook
            </Button>
            <Button
              component={Link}
              variant="text"
              color="warning"
              sx={{
                color: '#DD2A7B'
              }}
              size="large"
              startIcon={<InstagramIcon />}
              style={{marginRight: "5px"}}
              onClick={() => handleFooterClick("https://www.instagram.com/zumosgt/", "Instagram") }
            >
              Instagram
            </Button>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © Zumo's "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}