import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import React from "react";
import ReactGA from "react-ga4";

export const WhatsAppButton = () => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    ReactGA.event({
      category: 'User',
      action: 'WhatsApp flotante',
    });
    window.open("https://api.whatsapp.com/send?phone=50259843868", "_blank");
  }
  return (
    <>
      <a
        className="btn-whatsapp-pulse btn-whatsapp-pulse-border"
        referrerPolicy={"no-referrer"}
        onClick={handleClick}
      >
        <WhatsAppIcon sx={{
          fontSize: '40px'
        }}/>
      </a>
    </>
  )
}