import Box from "@mui/material/Box";
import MenuNavbar from "./MenuNavbar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/product/productSlice";
import { fetchCategories } from "../../redux/category/categorySlice";
import { MenuCategory } from "./MenuCategory";

export const ZumosMenu = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect( () => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
  }, []);

  return (
    <Box>
      <MenuNavbar />
      {categories && categories.length > 0 && (
        categories.map(item => <MenuCategory key={item._id} category={item}/>)
      )}
    </Box>
  )
}