import { configureStore } from '@reduxjs/toolkit'
import categorySlice from './category/categorySlice'
import productSlice from './product/productSlice'

const store = configureStore({
    reducer: {
        category: categorySlice,
        product: productSlice
    }
})

export default store