import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";

export default function HorarioComponent() {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Zumo's - Horario"
  }, []);
  return (
    <>
      <Box
        component="img"
        src="/img/horario.png"
        onLoad={() => setIsImageLoaded(true)}
        sx={{
          maxWidth: {
            xs: "100%",
          },
          objectFit: "cover",
          cursor: "pointer",
        }}
      >
      </Box>
      {!isImageLoaded &&
        <Skeleton
          height="500px"
          width="300px"
          sx={{
            margin: "0 auto"
          }}
        />
      }
    </>
  );
}
