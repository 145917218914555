import Button from "@mui/material/Button";
import MapIcon from '@mui/icons-material/Place';
import GoogleMapsIcon from '@mui/icons-material/Explore';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export const UbicacionComponent = () => {
  const [locationImageLoaded, setLocationImageLoaded] = useState<boolean>(false);
  useEffect(() => {
    document.title = "Zumo's - Ubicación";
  }, []);

  const handleLocationClick = (url: string, name: string) => {
    ReactGA.event({
      category: 'User',
      action: 'Ubicación - ' + name,
    });
    window.open(url, "_blank");
  }
  return (
    <Box marginBottom="30px">
      <Box
        component="img"
        src="/img/location/location.png"
        onLoad={() => setLocationImageLoaded(true)}
        sx={{
          maxWidth: {
            xs: "100%",
          },
          objectFit: "cover",
          cursor: "pointer",
        }}
      >
      </Box>
      {!locationImageLoaded &&
        <Skeleton
          height="300px"
          width="300px"
          sx={{
            margin: "0 auto"
          }}
        />
      }

      <Typography variant="h6" color="primary" marginTop="15px">
        Ver ubicación en:
      </Typography>
      <Button
        variant="outlined"
        color="success"
        size="large"
        endIcon={<GoogleMapsIcon />}
        style={{marginRight: "5px"}}
        onClick={() => handleLocationClick("https://goo.gl/maps/RcLU95w1F8CugXvAA", "Google Maps") }
      >
        Google Maps
      </Button>
      <Button
        variant="outlined"
        color="info"
        size="large"
        endIcon={<MapIcon />}
        style={{marginLeft: "5px"}}
        onClick={() => handleLocationClick("https://ul.waze.com/ul?place=ChIJy0ZUTLNHiYUR47JuZ7-yxLI&ll=14.75849490%2C-90.99594450&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location", "Waze") }
      >
        Waze
      </Button>
    </Box>
  )
}