import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";

function srcset(image: any, size: any, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function GaleriaComponent() {
  const matchDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    document.title = "Zumo's - Galería"
  }, []);

  return (
    <Box
      sx={{
        maxWidth: {
          xs: "100%",
        },
        objectFit: "cover",
        cursor: "pointer",
        marginTop: "-10px",
        marginBottom: "-10px",
      }}
    >
      <ImageList
        variant="quilted"
        cols={4}
        rowHeight={matchDownMd ? 100 : 300}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
            <img
              {...srcset(item.img, 400, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: 'img/galeria/square/1.png',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: 'img/galeria/square/2.png',
    title: 'Burger',
  },
  {
    img: 'img/galeria/square/3.png',
    title: 'Camera',
  },
  {
    img: 'img/galeria/rectangle/1.png',
    title: 'Coffee',
    cols: 2,
  },
  {
    img: 'img/galeria/rectangle/3.png',
    title: 'Hats',
    cols: 2,
  },
  {
    img: 'img/galeria/square/4.png',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: 'img/galeria/square/6.png',
    title: 'Basketball',
  },
  {
    img: 'img/galeria/square/8.png',
    title: 'Fern',
  },
  {
    img: 'img/galeria/square/9.png',
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: 'img/galeria/square/5.png',
    title: 'Tomato basil',
  },
  {
    img: 'img/galeria/square/7.png',
    title: 'Sea star',
  },
  {
    img: 'img/galeria/rectangle/2.png',
    title: 'Bike',
    cols: 2,
  },
  {
    img: 'img/galeria/rectangle/4.png',
    title: 'Hats',
    cols: 2,
  },
  {
    img: 'img/galeria/square/10.png',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: 'img/galeria/square/11.png',
    title: 'Basketball',
  },
  {
    img: 'img/galeria/square/12.png',
    title: 'Fern',
  },
  {
    img: 'img/galeria/square/13.png',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: 'img/galeria/square/14.png',
    title: 'Burger',
  },
  {
    img: 'img/galeria/square/15.png',
    title: 'Camera',
  },
  {
    img: 'img/galeria/rectangle/5.png',
    title: 'Coffee',
    cols: 2,
  },
];
