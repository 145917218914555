import React from 'react';
import './App.css';
import { ThemeProvider } from "@mui/material";
import { zumostheme } from "./theme/zumostheme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ZumosMenu } from "./Components/Menu/ZumosMenu.component";
import { MenuImagesComponent } from "./Components/MenuImages/MenuImages.component";
import { UbicacionComponent } from "./Components/Ubicacion/Ubicacion.component";
import Navbar from "./Components/Navbar";
import IndexComponent from "./Components/Index.component";
import HorarioComponent from "./Components/Horario.component";
import Box from "@mui/material/Box";
import { WhatsAppButton } from "./Components/Common/WhatsAppButton";
import FooterComponent from "./Components/Footer.component";
import GaleriaComponent from "./Components/Galeria.component";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  const theme = zumostheme;
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Box maxWidth={"1200px"} margin={"0 auto"}>
            <Routes>
              <Route path='/menu' element={<MenuImagesComponent />}></Route>
              <Route path='/ubicacion' element={<UbicacionComponent />}></Route>
              <Route path='/menu-nuevo' element={<ZumosMenu />}></Route>
              <Route path='/horario' element={<HorarioComponent />}></Route>
              <Route path='/galeria' element={<GaleriaComponent />}></Route>
              <Route path='*' element={<IndexComponent/>} />
            </Routes>
          </Box>

          <WhatsAppButton />

          <FooterComponent />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
