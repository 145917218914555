import { zumosApiConstants } from '../../constants/ZumosConstants'

const createSlice = require('@reduxjs/toolkit').createSlice
const createAsyncThunk = require('@reduxjs/toolkit').createAsyncThunk
const axios = require('axios')

const initialState = {
    loading: false,
    categories: [],
    error: '',
    test: {}
}

export const fetchCategories = createAsyncThunk('category/fetchCategories', () => {
    return axios.get(zumosApiConstants.CATEGORY_RESOURCE)
        .then((response) => response.data)
})

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.categories = action.payload
            state.error = ''
        })
        builder.addCase(fetchCategories.rejected, (state, action) => {
            state.loading = false;
            state.categories = []
            state.error = action.error.message
        })
    }
})

export const { changeTest } = categorySlice.actions
export default categorySlice.reducer